import { IObservationRegistryApi, ObservationData } from './api';
import RealtimeClient from '../../../utils/realtime-client';

// export const observations = (db: Firestore): IObservationRegistryApi => ({
//   async register(directoryId, observations) {
//     const batch = writeBatch(db);
//     observations.forEach(o => {
//       batch.set(doc(db, `or`, btoa(o.observationId)), o);
//     });

//     await Promise.resolve(batch.commit());
//   },

//   async find(directoryId, querySpec) {
//     const q = query(
//       collection(db, `or`),
//       where('objectId', '>=', querySpec.objectId),
//       where('objectId', '<=', querySpec.objectId + '\uf8ff')
//     );

//     const snapshot = await getDocs(q);
//     return snapshot.docs.map(d => d.data()).map(toObservationData);
//   },
// });

export const observations = (db: RealtimeClient): IObservationRegistryApi => ({
  async register(directoryId, observations) {
    for (const observation of observations) {
      await db.upsert('observations', observation.objectId, {
        observation,
        ...observation,
      });
    }
  },

  async find(directoryId, { objectId }) {
    const results = await db.findAll('observations', {
      startingWith: ['_id', objectId],
    });

    return results as any[];
  },
});

// const toObservationData = (doc: DocumentData): ObservationData =>
//   doc as ObservationData;
