import { motion } from 'framer-motion';
import buildOptimizedAssetPath from '../core/buildOptimizedAssetPath';
import useMousePosition from '../hooks/useMousePosition.hook';

const ParallaxBackground = () => {
  //const { x: mouseX, y: mouseY } = useMousePosition();

  return (
    <motion.div
      /*animate={{
        x: mouseX ? -mouseX / 450 : 0,
        y: mouseY ? -mouseY / 450 : 0,
        scale: 1.0,
      }}
      transition={{
        duration: 0.2,
        ease: 'linear',
      }}*/
      className="w-screen h-screen overflow-hidden absolute bottom-0 bg-cover bg-bottom"
      style={{
        backgroundImage: `url('${buildOptimizedAssetPath('images/bg_room', [
          'avif',
          'webp',
        ])}')`,
      }}
    />
  );
};

export default ParallaxBackground;
