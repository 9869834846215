import { LedspClient } from 'ledsp-sdk';
import gameConcept from '../../.ledsp-gc-manifest/Change U-1.0.0.json';
import useGameQueryParams from '../hooks/useGameQueryParams.hook';

export default function getLEDSPClient() {
  const { gameInfoId, demoId } = useGameQueryParams();

  if (!gameInfoId && !demoId)
    throw new Error('Missing or invalid gameInfoId or demoId!');

  return LedspClient.getInstance({
    gamePlayInfoId: gameInfoId || demoId,
    environment: process.env.ENV,
    emulate: !!demoId,
    gameConceptToEmulate: gameConcept,
    gameEventsMountPointId:
      process.env.ENV === 'development' ? 'events-debugger' : undefined,
  });
}
