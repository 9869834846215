import { IPlayer } from '../concepts/player';
import { StoreSlice } from '../../utils/store-slice';

interface IPrivate {}

export const create: StoreSlice<IPlayer & IPrivate> = (set, get) => {
  return {
    gameId: 'G1',
    playerId: 'P1',
    teamName: 'T1',
    sessionId: 'S1',
    isObserver: false,
    isWaitingSpokesperson: false
  };
};