import { motion } from 'framer-motion';
import buildAssetPath from '../core/buildAssetPath';
import { useMessages } from '../store/messages';
import { ExtractMultiLines } from './utils/extract-multi-lines';

const FullscreenAlert = () => {
  const t = useMessages(({ print }) => print);

  

  return (
    <motion.div
      animate={{
        y: [0, -4, 0, 4],
        rotateZ: [-0.4, 0, 0.4, 0],
      }}
      transition={{
        repeat: Infinity,
        duration: 4,
        repeatType: 'mirror',
        ease: 'linear',
      }}
      className="absolute z-[100] flex flex-row items-center w-auto p-1 mx-auto space-x-4 scale-75 bg-white border-2 border-blue-100 rounded-lg shadow-xl cursor-pointer 2xl:border-4 xl:scale-100 lg:p-2 xl:p-3 2xl:p-4 left-64 md:left-40 lg:left-48 xl:left-50 2xl:left-64 top-4 hover:border-blue-400"
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
         // Trigger fullscreen
          const element = document.documentElement as HTMLElement & {  
            webkitRequestFullscreen(): Promise<void>;
          };

        if (typeof document === 'undefined') return;
        // Set fullscreen
        if (document.fullscreenElement) document.exitFullscreen();
        else {
            if (element.requestFullscreen){element.requestFullscreen()}
            else { /* Safari */
            element.webkitRequestFullscreen();
          } 
      }}}
    >
      <img
        src={buildAssetPath('images/fullscreen.svg')}
        alt="Fullscreen"
        className="w-5 h-5 my-auto lg:w-6 lg:h-6 xl:w-7 xl:h-7 2xl:w-8 2xl:h-8"
      />
      <img
        src={buildAssetPath('images/fullscreen.svg')}
        alt="Fullscreen"
        className="absolute top-0 bottom-0 w-5 h-5 my-auto -left-2 opacity-20 lg:w-6 lg:h-6 xl:w-7 xl:h-7 2xl:w-8 2xl:h-8 animate-ping 2xl:left-1"
      />
      <p className="my-auto font-semibold text-xxs xl:text-xs 2xl:text-sm text-slate-600">
        {ExtractMultiLines(t('fullscreen-alert'))}
      </p>
    </motion.div>
  );
};

export default FullscreenAlert;
