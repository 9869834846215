import { useState } from 'react';
import clsx from 'clsx';
import { useGame } from '../../../store/game';
import { useMessages } from '../../../store/messages';
import { CoinIcon } from '../../game/coins-icon';
import { units } from '../../layout/formatters';

const IncludeActionInput = ({
  planId,
  after,
}: {
  planId: string;
  after: number;
}) => {
  const [value, setValue] = useState<string>('');
  const actions = useGame(({ searchPlannableActions }) =>
    searchPlannableActions(planId, value)
  );
  const includeActionInPlan = useGame(
    ({ includeActionInPlan }) => includeActionInPlan
  );
  const isActionExcluded = useGame(({ isActionExcluded }) => isActionExcluded);
  const getActionName = useMessages(
    ({ print }) =>
      (id: string) =>
        print(`action-${id}-name`)
  );
  const actionCost = useGame(({ actionCost }) => actionCost);
  const actionCode = useGame(({ actionCode }) => actionCode);
  const t = useMessages(({ print }) => print);

  return (
    <div className="mt-6">
      <p className="text-xs font-bold text-gray-500 uppercase">
        {t('plan-add-action')}
      </p>
      <div>
        <input
          className="w-full p-2 text-gray-600 rounded bg-slate-200/90 focus:outline-blue-100"
          type="text"
          placeholder={t('plan-search-action')}
          value={value}
          onChange={e => setValue(e.target.value)}
        />
      </div>

      <div className="h-32 p-1 py-1 space-y-1 overflow-y-scroll bg-white border rounded-b-lg shadow-xl scrollbar-document border-slate-200">
        {actions.map((action, actionIndex) => (
          <div
            key={actionIndex}
            title={getActionName(action)}
            className={clsx(
              'flex flex-row items-center justify-start py-px px-2 text-sm cursor-pointer truncate hover:bg-blue-100 rounded transition-all duration-200 ease-in-out',
              actionIndex % 2 === 0 ? 'bg-gray-200' : 'bg-slate-200/70',
              {
                'line-through opacity-30': isActionExcluded(action),
              }
            )}
            onClick={() => includeActionInPlan(planId, action, after)}
          >
            <p className="mr-2 font-bold text-slate-700">
              {`${actionCode(action)}`}
            </p>

            <p className="text-slate-800">{getActionName(action)}</p>

            <span className="flex flex-row items-center py-1 ml-auto space-x-1">
              <p className="font-semibold text-slate-500">
                {units(actionCost(action))}
              </p>
              <span className="scale-75 transform-gpu">
                <CoinIcon />
              </span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IncludeActionInput;
