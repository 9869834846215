import { initializeApp } from 'firebase/app';
import {
  doc,
  Firestore,
  getFirestore,
  collection,
  onSnapshot,
  setDoc,
  addDoc,
  Unsubscribe,
  getDoc,
  deleteDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';

export default class FirestoreClient {
  client: Firestore;
  subscriptions: Map<string, Unsubscribe> = new Map();

  constructor(configuration: IFirestoreConfiguration) {
    this.client = getFirestore(initializeApp(configuration));
  }

  // TODO: Check that this doesn't upsert, and throws an error if the record
  // exists already.
  public async create(_collection: string, id: string, payload: any) {
    return addDoc(collection(this.client, _collection), payload);
  }

  public async update(collection: string, id: string, payload: any) {
    return setDoc(doc(this.client, collection, id), payload);
  }

  public async findOne(collection: string, id: string) {
    try {
      const result = await getDoc(doc(this.client, collection, id));

      if (!result) throw new Error('Record not found.');

      return result.data();
    } catch (error) {
      console.error(error);

      return undefined;
    }
  }

  public async findAll(collectionName: string, filters?: Record<string, any>) {
    let firestoreFilters = filters.startingWith?.length
      ? where(filters.startingWith[0], '>=', filters.startingWith[1])
      : undefined;

    const results = await getDocs(
      query(collection(this.client, collectionName), firestoreFilters)
    );

    return results.docs.map(result => result.data());
  }

  public async upsert(collection: string, id: string, payload: any) {
    return setDoc(doc(this.client, collection, id), payload);
  }

  public async delete(collection: string, id: string) {
    return deleteDoc(doc(this.client, collection, id));
  }

  public subscribe(
    collection: string,
    recordId: string | undefined,
    callback: (event: any) => void
  ) {
    this.subscriptions.set(
      `${collection}${recordId ? `/${recordId}` : ''}`,
      onSnapshot(doc(this.client, collection, recordId), event =>
        callback(event.data())
      )
    );
  }

  public unsubscribe(collection: string, recordId: string | undefined) {
    const subscriptionId: string = `${collection}${
      recordId ? `/${recordId}` : ''
    }`;

    if (!this.subscriptions.has(subscriptionId)) return;

    this.subscriptions.get(subscriptionId)?.();

    this.subscriptions.delete(subscriptionId);
  }
}

export interface IFirestoreConfiguration {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}
