import numeral from 'numeral';
import 'numeral/locales/it'

export const price = (v: number) => `${numeral(v).format('0,0.0')} ₡`;

/**
 * @param separator is useless. Better to import and set the locale with numeral 
 */
export const percent = (v: number, decimals: number = 0, separator = '.') =>
  isNaN(v)
    ? '0%'
    : numeral(v).format(
        decimals ? `0.${'0'.repeat(decimals)}%` : '0%'
      );

export const units = (v: number) => numeral(v).format('0,0');


// NOTE. The functions above are not used

export const priceInK = (v: number) => {numeral(v).format('$ 0,0.0 a')};
export const taskCost = (v: number) => numeral(v).format('$ 0,0');
export const fixedCost = (v: number) => numeral(v).format('$ 0,0.00 a');
export const revenues = (v: number) => numeral(v).format('$ 0,0.00 a');
export const balance = (v: number) => numeral(v).format('$ 0,0.00 a');

export const level = (v: number) => numeral(v).format('0');
export const param = (v: number) => numeral(v).format('0.00');
