import { useEffect, useState } from 'react';
import MainLayout from './comps/layout/main-layout';
import LandingPage from './comps/layout/landing';
import { MessagesInitializer } from './comps/messages/initializer';
import { Initializer } from './comps/game/initializer';
import Debugger from './comps/debugger';
import useDiagnostics from './hooks/useDiagnostics.hook';
import { useGame } from './store/game';
import useGameQueryParams from './hooks/useGameQueryParams.hook';
import MissingGameData from './comps/layout/missing-game-data';
import { useMessages } from './store/messages';
import isEdge from './core/isEdge';
import isSafari from './core/isSafari';
import isChrome from './core/isChrome';
import '@reach/tooltip/styles.css';
import './styles/devices.css';
import './styles/globals.css';
import useFullscreen from './hooks/useFullscreen';
import FullscreenAlert from './comps/fullscreen-alert';

export const App = () => {
  const [isGameStarted, setGameStarted] = useState<boolean>(false);
  const isStarted = useGame(({ isStarted }) => isStarted);
  const start = useGame(({ start }) => start);
  const { gameInfoId, demoId } = useGameQueryParams();
  const { idiom, initialized } = useMessages(({ idiom, initialized }) => ({
    idiom,
    initialized,
  }));

  const isFullscreen = useFullscreen();

  useDiagnostics();

  useEffect(() => {
    console.table({
      branch: process.env.CF_PAGES_BRANCH,
      commit: process.env.GIT_COMMIT_HASH,
      isEdge: isEdge(),
      isSafari: isSafari(),
      isChrome: isChrome(),
    });
  }, []);

  if (typeof window !== 'undefined')
    if (window.location.href.endsWith('/debug')) return <Debugger />;

  if (!gameInfoId && !demoId) return <MissingGameData />;

  return (
    <Initializer>
      <MessagesInitializer />

      <div className="relative w-screen h-screen overflow-hidden">
        {idiom && initialized && !isFullscreen && <FullscreenAlert />}

        {!isGameStarted ? (
          <LandingPage
            handleGameStart={() => {
              if (!isStarted) start();
              setGameStarted(true);
            }}
          />
        ) : (
          typeof window !== 'undefined' && <MainLayout />
        )}
      </div>
    </Initializer>
  );
};
