import { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useGame } from '../../store/game';
import { useMessages } from '../../store/messages';
import { units } from '../layout/formatters';
import { alternateBackground, styler } from '../layout/styler';
import { ActionSummary } from '../plan/action-summary';
import { CoinIcon } from '../game/coins-icon';
import { StarIcon } from './plan/star-icon';
import { ExtractMultiLines } from '../utils/extract-multi-lines';

interface IProps {
  observerMode?: boolean;
}

const ActionsPortfolio = ({ observerMode }: IProps) => {
  const t = useMessages(({ print }) => print);
  const actions = useGame(({ actionsInPortfolio }) => actionsInPortfolio());
  const [selectedAction, setSelectedAction] = useState<string>();
  const scenarioName = useGame(({ scenarioName }) => scenarioName);
  const markedActions = useGame(({ markedActions }) => markedActions);

  const budget: number = useMemo(
    () =>
      scenarioName === 'crisis' ? 150 : scenarioName === 'neutral' ? 120 : 0,
    [scenarioName]
  );

  const [onlyShowStarred, setOnlyShowStarred] = useState<boolean>(false);

  return (
    <div className="p-8 overflow-y-scroll font-sans 2xl:p-10 3xl:p-12 scrollbar-document">
      <h2 className="m-0 text-xl font-medium leading-none 2xl:text-2xl 3xl:text-3xl">
        {t('actions-portfolio-title')}
      </h2>

      <br />

      <div>
        <motion.div
          className="relative flex flex-col"
          initial={{ width: '100%' }}
          animate={{ width: selectedAction ? '50%' : '100%' }}
          transition={{ duration: 0.6, easing: 'easeInOut' }}
        >
          {ExtractMultiLines(
            t('actions-portfolio-desc', { budget }),
            'w-4/5 text-xs 2xl:text-sm 3xl:text-base my-1'
          )}

          <button
            className="w-1/3 px-4 py-1 mt-16 font-semibold leading-tight text-blue-700 transition-all duration-200 ease-in-out rounded-full bg-blue-100/75 hover:bg-blue-100/100"
            onClick={() => setOnlyShowStarred(!onlyShowStarred)}
          >
            {t(
              onlyShowStarred
                ? 'actions-portfolio-star-filter-toggle-off'
                : 'actions-portfolio-star-filter-toggle-on'
            )}
          </button>

          <div className="flex flex-row w-full px-2 mt-2 mb-4 space-x-1 text-base font-semibold 2xl:text-lg 3xl:text-xl">
            <div className="w-7/12">{t('action')}</div>
            <div className="w-3/12 pr-2 text-right">{t('cost')}</div>
            {!observerMode && (
              <>
                <div className="flex items-center w-1/12 text-xs -rotate-90 -translate-y-8">
                  {t('draft')}
                </div>
                <div className="flex items-center w-1/12 text-xs -rotate-90 -translate-y-8">
                  {t('executive')}
                </div>
              </>
            )}
          </div>

          {actions
            .filter(action =>
              onlyShowStarred ? markedActions.includes(action) : true
            )
            .map((action, actionIndex) => (
              <ActionRow
                key={actionIndex}
                rank={actionIndex + 1}
                action={action}
                isSelected={action === selectedAction}
                handleClick={() =>
                  action === selectedAction
                    ? setSelectedAction(undefined)
                    : setSelectedAction(action)
                }
                observerMode={observerMode}
              />
            ))}
        </motion.div>

        {selectedAction && (
          <motion.div
            layout
            layoutId={selectedAction}
            key={selectedAction}
            className="absolute p-2 2xl:p-4 bg-blue-100 border-2 border-blue-400 w-[34%] top-20 2xl:top-28 right-24 b rounded-xl"
            initial={{ opacity: 0, x: -40, filter: 'blur(8px)' }}
            animate={{ opacity: 1, x: 0, filter: 'blur(0px)' }}
            transition={{ duration: 0.6, easing: 'easeInOut' }}
          >
            <ActionSummary
              actionId={selectedAction}
              observerMode={observerMode}
            />
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default ActionsPortfolio;

const ActionRow = (props: {
  rank: number;
  action: string;
  isSelected: boolean;
  observerMode?: boolean;
  handleClick: (action: string) => void;
}) => {
  const ref = useRef<HTMLDivElement>();
  const name = useMessages(({ print }) => print(`action-${props.action}-name`));
  const isInDraftPlan = useGame(({ isActionInPlan }) =>
    isActionInPlan('draft', props.action)
  );
  const isInExecutivePlan = useGame(({ isActionInPlan }) =>
    isActionInPlan('executive', props.action)
  );
  const isActionExcluded = useGame(({ isActionExcluded }) =>
    isActionExcluded(props.action)
  );
  const actionCost = useGame(({ actionCost }) => actionCost(props.action));
  const actionCode = useGame(({ actionCode }) => actionCode(props.action));

  const isActionMarked = useGame(s => s.isActionMarked(props.action));
  const markAction = useGame(s => s.markAction);
  const disregardAction = useGame(s => s.disregardAction);

  const [wasNotExcluded, exclude] = useState(false);

  useEffect(() => {
    if (isActionExcluded && !wasNotExcluded)
      ref.current.scrollIntoView({
        behavior: 'smooth',
      });
    if (isActionExcluded) exclude(true);
  }, [isActionExcluded]);

  return (
    <div
      ref={ref}
      className={clsx(
        'w-full flex flex-row items-center space-x-1 mb-2 p-2 border-4 rounded-lg cursor-pointer transition-all duration-200 ease-in-out',
        props.isSelected ? 'border-yellow-300' : 'border-transparent'
      )}
      style={styler(alternateBackground(['#eee', 'white'])(props.rank - 1))}
    >
      <div>
        <StarIcon
          selected={isActionMarked}
          onClick={
            isActionMarked
              ? () => disregardAction(props.action)
              : () => markAction(props.action)
          }
        />
      </div>

      <motion.div
        initial={{
          textDecoration: isActionExcluded ? 'line-through' : 'none',
          opacity: isActionExcluded ? 0.3 : 1,
        }}
        animate={{
          textDecoration: isActionExcluded ? 'line-through' : 'none',
          opacity: isActionExcluded ? 0.3 : 1,
        }}
        transition={{ duration: 2.4, easing: 'easeInOut' }}
        className="w-7/12 my-auto leading-none"
        onClick={() => props.handleClick(props.action)}
      >
        {/* <b style={{ display: 'initial' }}>{props.rank}</b>.{' '} */}
        <span className="inline-block text-sm font-bold text-slate-500">
          [{actionCode}]
        </span>{' '}
        {name}
      </motion.div>
      <div className="flex flex-row items-center justify-end w-3/12 px-2 space-x-1">
        <p className="font-semibold text-right text-gray-500">
          {units(actionCost)}
        </p>
        <CoinIcon />
      </div>
      {!props.observerMode && (
        <>
          <div className="w-1/12 3xl:pl-3 3xl:pr-2">
            {isInDraftPlan ? (
              <img
                src="https://cdn-icons-png.flaticon.com/128/6830/6830279.png"
                alt="Checkmark"
                className="w-6 ml-1"
              />
            ) : (
              ' '
            )}
          </div>
          <div className="w-1/12 pl-3 3xl:pl-4">
            {isInExecutivePlan ? (
              <img
                src="https://cdn-icons-png.flaticon.com/128/6830/6830279.png"
                className="w-6 ml-1"
                alt="Checkmark"
              />
            ) : (
              ' '
            )}
          </div>
        </>
      )}
    </div>
  );
};
