import buildOptimizedAssetPath from '../../core/buildOptimizedAssetPath';

interface IProps {
  variant: 'internal' | 'external';
  scaleFactor: number;
}

const BrandedHeader = ({ variant, scaleFactor }: IProps) => (
  <div
    className="select-none w-full bg-transparent grid grid-cols-3 grid-rows-1 gap-x-8 px-8 lg:px-10 xl:px-12 2xl:px-16 py-2 z-50"
    style={{ height: `100px` }}
  >
    <img
      src={buildOptimizedAssetPath('images/logo_bocconi', ['avif', 'png'])}
      alt="Logo Bocconi"
      className="h-1/2 mr-auto my-auto _nodrag"
    />
    <img
      src={buildOptimizedAssetPath('images/logo', ['avif', 'png'])}
      alt="Logo Bocconi"
      className="h-1/2 mx-auto my-auto _nodrag"
      style={{ imageRendering: '-webkit-optimize-contrast' }}
    />
    <img
      src={buildOptimizedAssetPath('images/logo_built_internal', [
        'avif',
        'png',
      ])}
      alt="Logo Bocconi"
      className="h-1/2 ml-auto my-auto _nodrag"
      style={{
        filter: 'drop-shadow(2px 4px 6px black)',
      }}
    />
  </div>
);

export default BrandedHeader;
