import { useGame } from '../../store/game';
import { useMessages } from '../../store/messages';
import { ListOfWords } from '../layout/list-of-words';

export const ActionPlanningInfo: React.FC<{ actionId: string }> = props => {
  const t = useMessages(({ print }) => print);
  const plans = useGame(s => s.plansWithAction(props.actionId));

  return (
    <>
      {plans.length === 0 && (
        <p className="bg-white/40 px-4 py-3 my-2 leading-none rounded text-blue-900/90 text-sm">
          <i>{t('action-not-included-in-plans')}</i>
        </p>
      )}

      {plans.length == 1 && (
        <p className="bg-white/40 px-4 py-3 my-2 leading-none text-sm text-blue-900/90">
          <i>
            {t('action-included-in-plan')}
            <b className="ml-1 mt-2">
              <ListOfWords children={plans.map(planId => t(planId))} />.
            </b>
          </i>
        </p>
      )}

      {plans.length > 1 && (
        <p className="bg-white/40 px-4 py-3 my-2 leading-none text-sm text-blue-900/90">
          <i>
            {t('action-included-in-plans')}
            <b className="ml-2 mt-2">
              <ListOfWords children={plans.map(planId => t(planId))} />.
            </b>
          </i>
        </p>
      )}
    </>
  );
};
