import { IGame } from '../concepts/game';
import { StoreSlice } from '../../utils/store-slice';
import { IActions, StrategicFits } from '../concepts/actions';
import FuzzySearch from 'fuzzy-search';
import { to } from '../../utils/mappers';
import { IPlan, KotterSteps } from '../concepts/plan';
import { actions } from '../../../mechanics/data/actions';
import { toSlice } from '../../../libs/say-it';
import { SequenceOfKotterSteps } from '../../../mechanics/objects/ideal-plan';
import seededShuffle from '../../../core/seededShuffle';
import produce from 'immer';

interface IPrivate {
  actions: string[];
  actionsDescription: ActionDescription[];
  actionsCost: Record<string, number>;
  actionsScore: Record<string, number>;
  actionsStrategicFit: Record<string, StrategicFits>;
  actionsKotterSteps: Record<string, KotterSteps>;
  actionsPU: Record<string, boolean>;
  actionsPEOU: Record<string, boolean>;
  markedActions: string[];
}

export const create: StoreSlice<IActions & IPrivate, IGame & IPlan> = (
  set,
  get
) => {
  return {
    actions: seededShuffle(actions, 1234).map(to('id')),
    actionsDescription: actions,
    actionsCost: actions.reduce(toSlice('id', 'cost'), {}),
    actionsScore: actions.reduce(toSlice('id', 'score'), {}),
    actionsStrategicFit: actions.reduce(toSlice('id', 'strategicFit'), {}),
    actionsKotterSteps: actions.reduce(toSlice('id', 'kotterStep'), {}),
    actionsPU: actions.reduce(toSlice('id', 'isPU'), {}),
    actionsPEOU: actions.reduce(toSlice('id', 'isPEOU'), {}),
    markedActions: [],

    actionIds() {
      return get().actions;
    },

    actionCost(id) {
      return get().actionsCost[id] || 0;
    },

    actionCode(id) {
      return `A${
        get().actionsDescription.findIndex(action => action.id === id) + 1
      }`;
    },

    actionKotterStep(id) {
      return get().actionsKotterSteps[id] || '--null--';
    },

    actionPhase(id) {
      if (
        ['urgency', 'coalition', 'vision-and-strategy'].includes(
          get().actionKotterStep(id)
        )
      )
        return 'preparation';

      if (['communicate'].includes(get().actionKotterStep(id)))
        return 'communication';

      if (
        [
          'empower-action',
          'short-term-wins',
          'consolidate-gains',
          'anchor-change',
        ].includes(get().actionKotterStep(id))
      )
        return 'execution';
    },

    actionStrategicFit(id) {
      return get().actionsStrategicFit[id] || 'neutral';
    },

    searchActions(pattern: string) {
      const searcher = new FuzzySearch(
        get().actionsDescription.map(action => ({
          ...action,
          code: get().actionCode(action.id),
        })),
        ['id', 'name', 'code']
      );

      // TODO: Fix types before using this stuff...
      // return searcher.search(pattern).map(to('id'));

      return searcher.search(pattern).map(({ id }) => id);
    },

    actionsInFitWith(scenario) {
      return get()
        .actions.filter(
          action =>
            get().actionStrategicFit(action) === scenario.strategyToAdopt ||
            get().actionStrategicFit(action) === 'neutral'
        )
        .sort((a, b) =>
          SequenceOfKotterSteps.compare(
            get().actionKotterStep(a),
            get().actionKotterStep(b)
          )
        );
    },

    actionsNotInFitWith(scenario) {
      return get().actions.filter(
        action =>
          get().actionStrategicFit(action) !== scenario.strategyToAdopt &&
          get().actionStrategicFit(action) !== 'neutral'
      );
    },

    isActionPU(actionId) {
      return get().actionsPU[actionId] || false;
    },

    isActionPEOU(actionId) {
      return get().actionsPEOU[actionId] || false;
    },

    isActionInFitWith(actionId, scenario, strict = false) {
      const strategicFit = get().actionStrategicFit(actionId);
      if (strategicFit === 'neutral' && !strict) return true;
      if (strategicFit === 'bottom-up' && scenario === 'neutral') return true;
      if (strategicFit === 'top-down' && scenario === 'crisis') return true;
      return false;
    },

    isActionMarked(actionId: string) {
      return get().markedActions.includes(actionId);
    },

    markAction(actionId: string) {
      if (get().markedActions.includes(actionId)) return;
      set(
        produce<IPrivate>(s => {
          s.markedActions.push(actionId);
        })
      );
    },

    disregardAction(actionId: string) {
      set(
        produce<IPrivate>(s => {
          s.markedActions = s.markedActions.filter(a => a != actionId);
        })
      );
    },
  };
};

export type ActionDescription = {
  id: string;
  name: string;
  description: string;
};
