import { IGame } from '../concepts/game';
import { ILogger } from '../concepts/logger';
import { IPlayer } from '../concepts/player';
import { IBudget } from '../concepts/budget';
import { StoreSlice } from '../../utils/store-slice';
import { createOrganizationScenario, IPlan } from '../concepts/plan';
import { IActions } from '../concepts/actions';
import { IDocuments } from '../concepts/documents';
import { actions } from '../../../mechanics/data/actions';
import getLEDSPClient from '../../../utils/get-ledsp-client';
// import download from 'downloadjs';

interface IPrivate {
  isGameEnded: boolean;
}

export const create: StoreSlice<
  IGame & IPrivate,
  IPlayer & ILogger & IPlan & IBudget & IActions & IDocuments
> = (set, get) => {
  return {
    isInitialized: false,
    isStarted: false,
    isDebugModeOn: false,
    scenarioName: 'neutral',
    endGameReturnPath: '/',
    volume: 0,
    isGameEnded: false,

    init(opts) {
      const plans = get().plans;
      const documents = get().availableDocuments;
      set({
        sessionId: opts.sessionId,
        gameId: opts.gameId,
        playerId: opts.playerId,
        teamName: opts.teamName,
        isObserver: opts.isObserver,
        isWaitingSpokesperson: opts.isObserver,
        volume: 0,
        scenarioName: opts.scenario,
        endGameReturnPath: opts.endGameReturnPath,
        scenario: createOrganizationScenario(opts.scenario),
        isInitialized: true,
        isGameEnded: false,
        initialBudget: opts.scenario === 'neutral' ? 120 : 150,
        availableDocuments:
          opts.devMode && !documents.length
            ? [
                'minuta',
                'rules',
                'actions-portfolio',
                'draft-plan',
                'executive-plan',
              ]
            : documents,
        plans:
          opts.devMode && !plans[0].actions.length
            ? [
                {
                  id: 'draft',
                  actions: actions.slice(0, 18).map(({ id }) => id),
                  pickedActions: [],
                  isSubmitted: false,
                },
              ]
            : plans,
      });
    },

    start() {
      set({
        isStarted: true,
      });

      if (!get().isObserver)
        getLEDSPClient().sendGameProgressEvent({
          gameId: get().gameId,
          playerId: get().playerId,
          eventType: 'game-started',
          teamId: get().teamName,
        });
    },

    async registerObservations(obs) {
      const sessionId = get().sessionId;
      console.log('registering observations', sessionId);

      const groupId: string = get().teamName;

      const g = `/sessions/${sessionId}/groups/${groupId}`;
      const p = `${g}/plans/executive`;
      const ev = get().planEvaluation('executive');

      const normalizedBudgetSpent =
        (get().planTotalCost('executive') + get().budgetSpent) /
        get().initialBudget;

      obs.describe(p, 'sessionId', sessionId);
      obs.describe(p, 'groupId', groupId);
      obs.describe(p, 'planId', 'executive');

      obs.measure(p, 'normalizedBudgetSpent', normalizedBudgetSpent);
      obs.measure(p, 'scenario', get().scenarioName);
      obs.measure(p, 'acceptanceRate', ev.acceptanceRate);
      obs.measure(p, 'invertedEntropy', ev.invertedEntropy);
      obs.measure(p, 'lossByEntropy', ev.lossDueToEntropy);
      obs.measure(p, 'lossByMissingActions', ev.lossDueToIncompleteness);
      obs.measure(p, 'lossByNotFittingActions', ev.lossDueToDisturbingActions);
      obs.measure(p, 'lossByMissingPUActions', ev.lossDueToPUIncompleteness);
      obs.measure(p, 'lossByMissingPEOUAction', ev.lossDueToPEOUIncompleteness);
      obs.measure(p, 'distanceFromCorrectPlan', ev.distanceFromIdealPlan);
      obs.measure(p, 'dispersion', ev.dispersion);
      obs.measure(
        p,
        'preparationPhasePerformance',
        ev.preparationPhasePerformance
      );
      obs.measure(
        p,
        'communicationPhasePerformance',
        ev.communicationPhasePerformance
      );
      obs.measure(p, 'executionPhasePerformance', ev.executionPhasePerformance);

      get()
        .actionsInPlan('executive')
        .forEach((actionId, i) => {
          const a = `${p}/actions/${actionId}`;

          obs.describe(a, 'sessionId', sessionId);
          obs.describe(a, 'groupId', groupId);
          obs.describe(a, 'planId', 'executive');
          obs.describe(a, 'actionId', actionId);

          obs.measure(a, 'isPU', get().isActionPU(actionId));
          obs.measure(a, 'isPEOU', get().isActionPEOU(actionId));
          obs.measure(
            a,
            'isInStrategicFit',
            get().isActionInFitWith(actionId, get().scenarioName)
          );
          obs.measure(
            a,
            'isInStrictStrategicFit',
            get().isActionInFitWith(actionId, get().scenarioName, true)
          );
          obs.measure(a, 'kotterStep', get().actionKotterStep(actionId));
          obs.measure(a, 'position', i);
        });

      get()
        .actionsInFitWith(get().scenario)
        .forEach((actionId, i) => {
          const a = `${g}/reference-plan/${actionId}`;

          obs.describe(a, 'sessionId', sessionId);
          obs.describe(a, 'groupId', groupId);
          obs.describe(a, 'planId', 'executive');
          obs.describe(a, 'actionId', actionId);

          obs.measure(a, 'isPU', get().isActionPU(actionId));
          obs.measure(a, 'isPEOU', get().isActionPEOU(actionId));
          obs.measure(
            a,
            'isInStrategicFit',
            get().isActionInFitWith(actionId, get().scenarioName)
          );
          obs.measure(
            a,
            'isInStrictStrategicFit',
            get().isActionInFitWith(actionId, get().scenarioName, true)
          );
          obs.measure(a, 'isUsed', get().isActionInPlan('executive', actionId));
          obs.measure(a, 'kotterStep', get().actionKotterStep(actionId));
          obs.measure(a, 'position', i);
        });

      // download(
      //   JSON.stringify(obs.find('**')),
      //   `change-u-${get().gameId}:${get().playerId}.observations`
      // );

      return obs
        .save('main')
        .then(() => get().log('observations saved successfully'))
        .catch(error => get().error(error));
    },

    toggleVolume: () => {
      if (get().volume > 0) {
        set({ volume: 0 });
        Howler.volume(0);
      } else {
        set({ volume: 1 });
        Howler.volume(1);
      }
    },

    isSoundOn: () => get().volume > 0,
  };
};
