import { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Lottie from 'react-lottie-player';
import ActionsPortfolio from './actions-portfolio';
import { Plan } from './plan';
import NudgeButton from './nudge-button';
import Window from './window';
import { useGame } from '../../store/game';
import DraftPlanResponse from './draft-plan-response';
import Minuta from './minuta';
import Rules from './rules';
import Document from './document';
import FinalResults from './final-results';
import { icons } from './documents-data';
import animation from '../../lottie-animations/90810-qr-code-scanning.json';
import { useMessages } from '../../store/messages';
import buildAssetPath from '../../core/buildAssetPath';

// TODO: Play SFX on document opening.
const Explorer = () => {
  const t = useMessages(({ print }) => print);
  const documents = useGame(({ availableDocuments }) => availableDocuments);
  const currentPlan = useGame(({ currentPlan }) => currentPlan);
  const isObserver = useGame(s => s.isObserver);
  const isPlanSubmitted = useGame(({ isPlanSubmitted }) => isPlanSubmitted);
  const openedFile = useGame(
    ({ currentActiveDocument }) => currentActiveDocument
  );
  const setOpenedFile = useGame(({ openDocument }) => openDocument);
  const closeFile = useGame(({ closeActiveDocument }) => closeActiveDocument);
  const { gameId, teamName, playerId } = useGame(
    ({ gameId, teamName, playerId, isObserver }) => ({
      gameId,
      teamName,
      playerId,
      isObserver,
    })
  );

  const [isScanning, setScanning] = useState<boolean>(false);

  useEffect(function autoclose(){if(isObserver&&!openedFile!=null){closeFile()}},[documents])

  const explorerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="relative w-[100%] left-0 top-0 h-6 bg-white sticky flex flex-row px-4 items-center justify-between select-none pointer-events-none shadow-xl">
        <div className="flex flex-row space-x-4 items-center h-full">
          <img className="w-4" src={buildAssetPath('images/gbp-logo.svg')} />
          <span className="text-xs font-bold text-slate-900">
            Team {teamName || '~'}
          </span>
          <span className="font-bold text-slate-500 text-xs opacity-80">
            ({(isObserver ? 'Observer' : 'Spokesperson') + ' mode'})
          </span>
        </div>

        <div className="flex flex-row space-x-4 items-center h-full">
          <img className="h-3" src={buildAssetPath('images/gbp-wifi.svg')} />
          <img
            className="h-3"
            src={buildAssetPath('images/gbp-speaker.svg')}
          />
          <img
            className="h-4"
            src={buildAssetPath('images/gbp-battery.svg')}
          />
        </div>
      </div>
      <div
        className="absolute left-0 right-0 flex flex-row flex-wrap grow-0 max-w-full w-[100%] h-[100%] gap-8 lg:gap-12 xl:gap-24 p-10 lg:p-12 xl:p-16 2xl:p-16
        overflow-y-auto"
        ref={explorerRef}
      >

        {documents.map((file, fileIndex) => (
          <Document
            key={fileIndex}
            handleOpen={() => setOpenedFile(file)}
            file={file}
          />
        ))}

        {openedFile === 'final-results' && (
          <Window
            icon={icons[openedFile]}
            title={t(`${openedFile}-title`)}
            parentRef={explorerRef}
            width="64%"
            height="86%"
            onClose={closeFile}
          >
            <FinalResults />
          </Window>
        )}

        {openedFile === 'draft-response' && (
          <Window
            icon={icons[openedFile]}
            title={t(`${openedFile}-title`)}
            parentRef={explorerRef}
            width="64%"
            height="86%"
            onClose={closeFile}
          >
            <DraftPlanResponse />
          </Window>
        )}

        {openedFile === 'draft-plan' && (
          <Window
            icon={icons[openedFile]}
            title={t(`${openedFile}-title`)}
            parentRef={explorerRef}
            width="90%"
            height="89%"
            onClose={closeFile}
            toolbarItems={
              !isObserver &&
              currentPlan === 'draft' && (
                <NudgeButton
                  nudgeId="xray"
                  onStart={() => setScanning(true)}
                  onEnd={() => setScanning(false)}
                />
              )
            }
          >
            {isScanning && (
              <motion.div
                className="absolute inset-0 z-50 h-[96%] my-auto mr-64 top-10 pointer-events-none"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
              >
                <Lottie
                  play
                  animationData={animation}
                  style={{ height: '100%' }}
                />
              </motion.div>
            )}
            <Plan planId="draft" />
          </Window>
        )}

        {openedFile === 'executive-plan' && (
          <Window
            icon={icons[openedFile]}
            title={t(`${openedFile}-title`)}
            parentRef={explorerRef}
            width="90%"
            height="89%"
            onClose={() => closeFile()}
            toolbarItems={
              !isObserver &&
              currentPlan === 'executive' &&
              !isPlanSubmitted('executive') && (
                <NudgeButton
                  nudgeId="xray"
                  onStart={() => setScanning(true)}
                  onEnd={() => setScanning(false)}
                />
              )
            }
          >
            {isScanning && (
              <motion.div
                className="absolute inset-0 z-50 h-[96%] my-auto mr-64 top-10 pointer-events-none"
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
              >
                <Lottie
                  play
                  animationData={animation}
                  style={{ height: '100%' }}
                />
              </motion.div>
            )}
            <Plan planId="executive" />
          </Window>
        )}

        {openedFile === 'actions-portfolio' && (
          <Window
            icon={icons[openedFile]}
            title={t(`${openedFile}-title`)}
            parentRef={explorerRef}
            width="90%"
            height="86%"
            toolbarItems={
              !isObserver &&
              !isPlanSubmitted('executive') && (
                <NudgeButton nudgeId="laser" delay={100} />
              )
            }
            onClose={closeFile}
          >
            <ActionsPortfolio />
          </Window>
        )}

        {openedFile === 'minuta' && (
          <Window
            icon={icons[openedFile]}
            title={t(`${openedFile}-title`)}
            parentRef={explorerRef}
            width="90%"
            height="80%"
            onClose={closeFile}
          >
            <Minuta />
          </Window>
        )}

        {openedFile === 'rules' && (
          <Window
            icon={icons[openedFile]}
            title={t(`${openedFile}-title`)}
            parentRef={explorerRef}
            width="90%"
            height="80%"
            onClose={closeFile}
          >
            <Rules />
          </Window>
        )}
      </div>
    </>
  );
};

export default Explorer;
