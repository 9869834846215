import create, { GetState, SetState } from 'zustand';
import { persist, StateStorage } from 'zustand/middleware';
import * as Game from './factories/game';
import * as Logger from './factories/logger';
import * as Player from './factories/player';
import * as Plan from './factories/plan';
import * as Actions from './factories/actions';
import * as Feed from './factories/feed';
import * as Budget from './factories/budget';
import * as Nudges from './factories/nudges';
import * as Documents from './factories/documents';
import * as Messages from './factories/messages';
import log from '../../core/log';

import { live } from '../../libs/zustand-live';
import RealtimeClient from '../../utils/realtime-client';

const DEFAULT_STORE_NAME = 'change-u';

const createRootSlice = (set: SetState<any>, get: GetState<any>) => ({
  ...Game.create(set, get),
  ...Logger.create(set, get),
  ...Player.create(set, get),
  ...Actions.create(set, get),
  ...Plan.create(set, get),
  ...Feed.create(set, get),
  ...Budget.create(set, get),
  ...Nudges.create(set, get),
  ...Documents.create(set, get),
  ...Messages.create(set, get),
});

type StoreType = ReturnType<typeof createRootSlice>;

export const useGame = create(
  persist(
    live<StoreType>(createRootSlice)({
      filter: s => {
        const keys = [
          'plans',
          'excludedActions',
          'actionsInPartialOrder',
          'currentPlan',
          'budgetSpent',
        ];
        if (s.currentPlan === 'executive') keys.push('availableDocuments');

        return keys.reduce((sy, k) => ({ ...sy, [k]: s[k] }), {});
      },
      db: new RealtimeClient(
        process.env.REALTIME_STORE_ENGINE as ConstructorParameters<
          typeof RealtimeClient
        >[0],
        JSON.parse(
          process.env.REALTIME_STORE_CONFIGURATION
        ) as ConstructorParameters<typeof RealtimeClient>[1]
      ),
    }),
    {
      name: DEFAULT_STORE_NAME,

      onRehydrateStorage: state => {
        log('Hydration started', 'Game store');

        return (state, error) => {
          if (error)
            log('an error happened during hydration: ' + error, 'Game store');
          else log('Hydration finished', 'Game store');
        };
      },

      getStorage: (): StateStorage => ({
        getItem(key: string) {
          return localStorage.getItem(key);
        },
        setItem(key: string, value: string) {
          if (key !== DEFAULT_STORE_NAME) localStorage.setItem(key, value);
        },
        removeItem(key) {
          return localStorage.removeItem(key);
        },
      }),

      partialize(state) {
        return Object.fromEntries(
          Object.entries(state).filter(([key]) => !['live'].includes(key))
        );
      },
    }
  )
);

// @ts-ignore
window.game = useGame;
