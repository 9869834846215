import { ifIn, toClusters, toGroupsBy } from '../../libs/say-it';
import { IPlan } from '../concepts';

export class Plan implements IPlan {
  static create(actions: string[]) {
    return new Plan(actions);
  }

  constructor(private readonly actions: string[]) {}

  toMacrophases(macrophase: (actionId: string) => string): string[] {
    return this.actions.map(macrophase);
  }

  toClusters(kotterStep: (actionId: string) => string): string[] {
    return this.actions.map(kotterStep).reduce(toClusters(), []);
  }

  countActionIfIncluded(actions: string[]): number {
    return actions.filter(ifIn(this.actions)).length;
  }

  lossDueToMissingActions(idealActions: string[]): number {
    const t = idealActions.length;
    const i = this.countActionIfIncluded(idealActions);
    return (t - i) / t;
  }

  lossDueToDisturbingActions(disturbingActions: string[]): number {
    const t = disturbingActions.length;
    const i = this.countActionIfIncluded(disturbingActions);
    return i / t;
  }
}
