import { ReactNode } from 'react';
import { motion } from 'framer-motion';
import buildOptimizedAssetPath from '../../core/buildOptimizedAssetPath';

interface IProps {
  children: ReactNode | JSX.Element | JSX.Element[];
}

const ComputerFrame = ({ children }: IProps) => (
  <motion.div
    className="device device-macbook-pro device-spacegray select-none"
    layoutId="computer-frame"
    key="computer-frame"
  >
    <div className="device-frame relative overflow-hidden">
      <div
        className="absolute w-full h-full left-0 top-0 bg-cover z-10 pointer-events-none"
        style={{
          backgroundImage: `url('${buildOptimizedAssetPath(
            'images/computer-glass',
            ['avif', 'jpg']
          )}')`,
          opacity: 0.2,
        }}
      />
      <div
        className="device-content flex relative overflow-hidden"
        style={{
          backgroundImage: `url('${buildOptimizedAssetPath(
            'images/computer-bg',
            ['avif', 'jpg']
          )}')`,
          boxShadow: 'inset 0 0 10px #00000050, inset 0 0 8px #00000040',
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <div className="absolute top-16 left-4 filter w-4/5 h-40 z-30 transform-gpu -rotate-6 blur-lg opacity-30 pointer-events-none" />
        <div className="flex-grow w-full max-w-full overflow-hidden">
          {children}
        </div>
      </div>
    </div>

    <div className="device-stripe" />
    <div className="device-header" />
    <div className="device-sensors" />
    <div className="device-btns" />
    <div className="device-power" />
  </motion.div>
);

export default ComputerFrame;
