import { useEffect, useState } from 'react';

export default function useFullscreen() {
  const [isFullscreen, setFullscreen] = useState<boolean>(false);

  useEffect(() => {
    if (typeof document === 'undefined') return;

    const onFullscreenChange = () => {
      setFullscreen(
        !!document.fullscreenElement
        || !! document['webkitFullscreenElement']
        || !! document['mozFullScreenElement']
        || !! document['msFullscreenElement']
      );
    }

    document.addEventListener('fullscreenchange', onFullscreenChange);
    document.addEventListener('webkitfullscreenchange', onFullscreenChange);
    document.addEventListener('mozfullscreenchange', onFullscreenChange);
    document.addEventListener('msfullscreenchange', onFullscreenChange);
    
    return () => {
      document.removeEventListener('fullscreenchange', onFullscreenChange);
    };
  }, []);

  return isFullscreen;
}
