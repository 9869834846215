import { useEffect, useState } from 'react';
import QueryString from 'query-string';
import { useGame } from '../../store/game';
import useGameQueryParams from '../../hooks/useGameQueryParams.hook';
import getLEDSPClient from '../../utils/get-ledsp-client';
import ServicePage from '../service-page';
import { useMessages } from '../../store/messages';

interface IProps {
  children: JSX.Element | JSX.Element[];
}

export const Initializer = ({ children }: IProps): JSX.Element => {
  const { isObserver, idiom: qsIdiom } = useGameQueryParams();

  const init = useGame(({ init }) => init);
  const { observe, sync } = useGame(({ live }) => live);
  const isInitialized = useGame(({ isInitialized }) => isInitialized);
  const messages = useMessages(store => store);

  const [parsedGameId, setParsedGameId] = useState<string>();
  const [parsedPlayerId, setParsedPlayerId] = useState<string>();
  const [isReady, setReady] = useState<boolean>(false);

  useEffect(() => {
    if (isInitialized) return;

    // Manual overrides handling.
    const parsed = QueryString.parse(location.search);
    const team = parsed.t?.toString();
    const returnPath = parsed.rp?.toString();

    getLEDSPClient()
      .gamePlayInfo({ ...parsed, team: { id: team, name: team } })
      .then(gamePlayInfo => {
        // TODO Should be retrieved from the game play info
        const sessionId: string =
          parsed?.s?.toString() || gamePlayInfo.sessionId;
        const gameId: string = gamePlayInfo.gameId;
        const playerId: string = gamePlayInfo.playerId;
        const teamName: string = gamePlayInfo.team.name;
        const idiom: string =
          gamePlayInfo.settings.configuration.playOptions?.idiom?.toString() ||
          qsIdiom ||
          'en';
        const devMode: boolean = parsed?.d?.toString() === 'true';

        // TODO: Make this more robust. Maybe the else branch should be the gamePlayInfo returnPath.
        const endGameReturnPath: string =
          !gamePlayInfo?.settings.configuration.returnPath ||
          gamePlayInfo?.settings.configuration.returnPath === '#'
            ? `${returnPath}?session=${sessionId}&idiom=${idiom}&teamName=${teamName}`
            : '#';

        const scenario: string = ['crisis', 'neutral'].find(s =>
          s.startsWith(parsed?.scenario?.toString())
        );

        const scenarioName: string =
          scenario ||
          gamePlayInfo?.settings.configuration.playOptions?.scenarioName.toString() ||
          'neutral';

        setParsedGameId(gameId);
        setParsedPlayerId(playerId);

        messages.changeIdiom(idiom);

        if (!gameId || !playerId) return;

        useGame.persist.setOptions({ name: `change-u-${gameId}:${playerId}` });

        useGame.persist.rehydrate().then(() => {
          const isTrulyObserver: boolean =
            isObserver || window.location.href.includes('observer=true');

          init({
            sessionId,
            devMode,
            gameId,
            playerId,
            teamName,
            scenario: scenarioName as any,
            endGameReturnPath,
            isObserver: isTrulyObserver,
          });

          if (isTrulyObserver) (async () => {
            let isSubscribed = false;
            while (!isSubscribed) {
              isSubscribed = await observe(gameId);
              if (!isSubscribed)
                await new Promise(r => setTimeout(r, 5000));
            }
          })();
          else sync(gameId);

          setReady(true);
        });
      });
  }, []);

  if (!isReady) return <p>Loading...</p>;

  // If everything is ready, render the children.
  if (isReady) return <>{children}</>;

  // If we're not ready, and no game id was retrieved, then we're should not be
  // here.
  if (isReady && !parsedGameId) return <ServicePage>Invalid link.</ServicePage>;

  // If we're not ready, but we have a game id and a player id, then it's a
  // matter of waiting.
  if (parsedPlayerId) return <ServicePage>Redirecting</ServicePage>;

  // If we're here, then we have a game id, but no player id. Let's show a
  // selector to let the user pick a player.
  return (
    <div className="flex flex-col space-y-8">
      <h1>Please pick a player to proceed</h1>

      <br />

      {Array.from({ length: 10 }, (_, index) => (
        <div
          className="w-full p-4 text-lg text-blue-900 transition-all duration-200 ease-in-out scale-100 bg-blue-100 border-2 border-blue-700 rounded-lg cursor-pointer transform-gpu hover:scale-105 hover:duration-75"
          key={index}
          onClick={() => {
            if (typeof window === 'undefined') return;

            const baseURL: string = window.location
              .toString()
              .replace(/[^\/]+\/?$/, '');

            window.location.assign(
              `${baseURL}?g=${parsedGameId}&p=P${index + 1}`
            );
          }}
        >
          Play as Player {index + 1}
        </div>
      ))}
    </div>
  );
};
