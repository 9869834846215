import { GetState, SetState, StateCreator, StoreApi } from 'zustand';
import RealtimeClient from '../../utils/realtime-client';
import { DocumentData } from 'firebase/firestore';

export type ZustandState = Record<string, unknown>;

export type LiveState<T extends ZustandState> = T & {
  readonly live: {
    observe(stateId: string): Promise<boolean>;
    sync(stateId: string): void;
  };
};

export const live =
  <T extends ZustandState>(
    config: StateCreator<T, SetState<T>, GetState<LiveState<T>>, StoreApi<T>>
  ) =>
  (opts: {
    filter: (s: T) => Partial<T>;
    db: RealtimeClient;
  }): StateCreator<
    LiveState<T>,
    SetState<LiveState<T>>,
    GetState<LiveState<T>>,
    StoreApi<LiveState<T>>
  > =>
  (set: any, get, api: any) => {
    let isObserving = true;
    let stateId: string = null;
    let unsubscribe: any = () => {};

    const store = config(
      s => {
        set(s);

        if (isObserving) return;

        const stateToSync = opts.filter(get());

        opts.db
          .upsert('games', stateId, {
            playerId: stateId,
            state: stateToSync,
          })
          .then(() => console.log('live state dumped'))
          .catch(error =>
            console.error('error while dumping live state:', error)
          );
      },
      get,
      api
    );

    async function  observe(stateId: string): Promise<boolean> {
      console.log('looking for game to observe...', stateId);
      isObserving = true;

      const data = await opts.db.findOne('games', stateId);
      if (data) {
        console.log('game found... start observing...', stateId);

        const { id, state } = data;
        set(state);

        opts.db.subscribe('games', id, (record: { state: any; } & any) => {
          console.log('new state ready', record.state);
          set(record.state);
        });
        set({isWaitingSpokesperson : false});
        return true;
      }
      else {
        set({isWaitingSpokesperson : true});
        return false;
      }
    }

    function sync(gameId: string) {
      console.log('start syncing...', gameId);

      opts.db.unsubscribe('games', stateId);

      isObserving = false;
      stateId = gameId;
    }

    return {
      ...store,
      live: {
        observe,
        sync,
      },
    };
  };
