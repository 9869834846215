import clsx from 'clsx';
import { useGame } from '../../../store/game';
import { useMessages } from '../../../store/messages';

interface IProps {
  actionId: string;
  targetActionId: string;
}

const OrderNudge = (props: IProps) => {
  const shouldActionBeBefore = useGame(({ shouldActionBeBefore }) =>
    shouldActionBeBefore(props.actionId, props.targetActionId)
  );

  const getActionName = useMessages(
    ({ print }) =>
      id =>
        print(`action-${id}-name`)
  );
  const t = useMessages(({ print }) => print);

  if (
    shouldActionBeBefore === 'unsure' ||
    props.actionId === props.targetActionId
  )
    return null;

  return (
    <div className="relative group">
      <span
        className={clsx(
          'absolute z-40 block w-56 px-3 py-2 top-8 font-sans text-xs leading-tight rounded shadow-lg transition-all duration-200 ease-in-out group-hover:inline-block hidden backdrop-blur-sm right-8 border-b border-black bg-black/80 3xl:text-sm 3xl:leading-tight',
          shouldActionBeBefore === 'yes' ? 'text-green-100' : 'text-red-100'
        )}
      >
        {t('nudge-executing')} «{getActionName(props.actionId)}»{' '}
        {t('nudge-before')} «{getActionName(props.targetActionId)}»{' '}
        {t(`nudge-${shouldActionBeBefore === 'yes' ? 'good' : 'bad'}-idea`)}
      </span>
      <span
        className={clsx(
          'relative flex items-center justify-center w-4 h-4 p-1 font-bold text-white rounded-full xl:w-6 xl:h-6 cursor-help',
          shouldActionBeBefore === 'yes' ? 'bg-green-500' : 'bg-red-500'
        )}
      >
        {shouldActionBeBefore === 'yes' ? '✓' : '!'}
      </span>
    </div>
  );
};

export default OrderNudge;
