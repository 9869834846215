import { Conversation } from '../store/game/concepts/messages';

const conversation: Conversation = [
  {
    type: 'text',
    text: 'chat-intro-1',
    timeToWrite: 1.5,
    timeToRead: 0.5,
  },
  {
    type: 'text',
    text: 'chat-intro-2',
    timeToWrite: 2.5,
    timeToRead: 1.5,
  },
  {
    type: 'text',
    text: 'chat-intro-3',
    timeToWrite: 4,
    timeToRead: 2,
  },
  {
    type: 'text',
    text: 'chat-intro-4',
    timeToWrite: 4,
    timeToRead: 2,
  },
  {
    type: 'text',
    text: 'chat-intro-5a',
    timeToWrite: 4,
    timeToRead: 2,
  },
  {
    type: 'text',
    text: 'chat-intro-5b',
    timeToWrite: 4,
    timeToRead: 2,
  },
  {
    type: 'text',
    text: 'chat-intro-6',
    timeToWrite: 4,
    timeToRead: 2,
  },
  {
    type: 'attachment',
    attachment: 'minuta',
  },
  {
    type: 'text',
    text: 'chat-intro-7',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-intro-8',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'question',
    questionId: 'minuta-read',
    options: [{ text: 'chat-answer-1' }],
  },
  {
    type: 'text',
    text: 'chat-intro-9',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'attachment',
    attachment: 'rules',
  },
  {
    type: 'text',
    text: 'chat-intro-10',
    timeToWrite: 1,
    timeToRead: 0.5,
  },
  {
    type: 'question',
    questionId: 'instructions-explanation',
    options: [{ text: 'chat-answer-2' }],
  },
  {
    type: 'text',
    text: 'chat-intro-11a',
    timeToWrite: 3,
    timeToRead: 2,
  },
  {
    type: 'text',
    text: 'chat-intro-11b',
    timeToWrite: 3,
    timeToRead: 2,
  },
  {
    type: 'text',
    text: 'chat-intro-11c',
    timeToWrite: 3,
    timeToRead: 2,
  },
  {
    type: 'attachment',
    attachment: 'actions-portfolio',
  },
  {
    type: 'text',
    text: 'chat-intro-12',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'question',
    questionId: 'actions-portfolio-read',
    options: [{ text: 'chat-answer-3' }],
  },
  {
    type: 'text',
    text: 'chat-intro-13',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-intro-14',
    timeToWrite: 4,
    timeToRead: 3,
  },
  {
    type: 'text',
    text: 'chat-intro-15',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-intro-16',
    timeToWrite: 1,
    timeToRead: 0.5,
  },
  {
    type: 'question',
    questionId: 'plan-explanation',
    options: [{ text: 'chat-answer-4' }],
  },
  {
    type: 'text',
    text: 'chat-intro-17',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-intro-18',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-intro-19',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-intro-20',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'question',
    questionId: 'ready-to-play',
    options: [{ text: 'chat-answer-5' }],
  },
  {
    type: 'text',
    text: 'chat-intro-21',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'question',
    questionId: 'draft-plan-ready',
    options: [{ text: 'chat-answer-6' }],
  },
  {
    type: 'text',
    text: 'chat-draft-1',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'question',
    questionId: 'draft-plan-confirm',
    options: [{ text: 'chat-answer-7', attachment: 'draft-plan' }],
  },
  {
    type: 'text',
    text: 'chat-draft-2',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'chapter-end',
    phaseId: 'draft',
  },
  {
    type: 'text',
    text: 'chat-draft-3',
    timeToWrite: 1,
    timeToRead: 0.5,
  },
  {
    type: 'text',
    text: 'chat-draft-4',
    timeToWrite: 1,
    timeToRead: 0.5,
  },
  {
    type: 'text',
    text: 'chat-draft-5',
    timeToWrite: 1,
    timeToRead: 0.5,
  },
  {
    type: 'attachment',
    attachment: 'draft-response',
  },
  {
    type: 'text',
    text: 'chat-draft-6',
    timeToWrite: 1,
    timeToRead: 0.5,
  },
  {
    type: 'question',
    questionId: 'draft-feedback-read',
    options: [{ text: 'chat-answer-8' }],
  },
  {
    type: 'text',
    text: 'chat-executive-1',
    timeToWrite: 1.5,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-executive-2',
    timeToWrite: 1.5,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-executive-3',
    timeToWrite: 1.5,
    timeToRead: 1,
  },
  {
    type: 'question',
    questionId: 'executive-plan-ready',
    options: [{ text: 'chat-answer-9' }],
  },
  {
    type: 'text',
    text: 'chat-executive-4',
    timeToWrite: 4,
    timeToRead: 2,
  },
  {
    type: 'question',
    questionId: 'executive-plan-confirm',
    options: [{ text: 'chat-answer-10', attachment: 'executive-plan' }],
  },
  {
    type: 'text',
    text: 'chat-executive-5',
    timeToWrite: 3,
    timeToRead: 1.5,
  },
  {
    type: 'text',
    text: 'chat-executive-6',
    timeToWrite: 1,
    timeToRead: 0.5,
  },
  {
    type: 'text',
    text: 'chat-executive-7',
    timeToWrite: 1,
    timeToRead: 0.5,
  },
  {
    type: 'chapter-end',
    phaseId: 'final',
  },
  {
    type: 'text',
    text: 'chat-final-1',
    timeToWrite: 1.5,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-final-2',
    timeToWrite: 2.5,
    timeToRead: 1.5,
  },
  {
    type: 'text',
    text: 'chat-final-3',
    timeToWrite: 1.5,
    timeToRead: 1,
  },
  {
    type: 'attachment',
    attachment: 'final-results',
  },
  {
    type: 'text',
    text: 'chat-final-4',
    timeToWrite: 1.5,
    timeToRead: 1,
  },
  {
    type: 'question',
    questionId: 'final-results-read',
    options: [{ text: 'chat-answer-11' }],
  },
  {
    type: 'text',
    text: 'chat-final-5',
    timeToWrite: 3,
    timeToRead: 1.5,
  },
  {
    type: 'text',
    text: 'chat-final-6',
    timeToWrite: 3,
    timeToRead: 1.5,
  },
  {
    type: 'text',
    text: 'chat-final-7',
    timeToWrite: 4,
    timeToRead: 2,
  },
  {
    type: 'external-link',
    text: 'chat-final-8',
    link: 'endGameReturnPath',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-final-9',
    timeToWrite: 2,
    timeToRead: 1,
  },
  {
    type: 'text',
    text: 'chat-final-10',
    timeToWrite: 1.5,
    timeToRead: 0.5,
  },
];

export default conversation;
