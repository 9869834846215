import { motion } from 'framer-motion';
import ComputerFrame from '../computer/computer-frame';
import useMousePosition from '../../hooks/useMousePosition.hook';
import buildOptimizedAssetPath from '../../core/buildOptimizedAssetPath';
import useScaleFactor from '../../hooks/useScaleFactor.hook';
import BrandedHeader from './branded-header';
import buildAssetPath from '../../core/buildAssetPath';
import { useMessages } from '../../store/messages';
import { MessagesInitializer } from '../messages/initializer';

const MissingGameData = () => {
  const t = useMessages(({ print }) => print);
  //const { x: mouseX, y: mouseY } = useMousePosition();
  const scaleFactor: number = useScaleFactor();

  return (
    <>
      <MessagesInitializer />
      <div className="flex flex-col items-center justify-center w-screen h-screen p-8 lg:hidden text-4xl">
        <p>Please use a higher resolution display to play this game.</p>
      </div>

      <div className="md:flex flex-col w-screen h-screen overflow-hidden hidden">
        <BrandedHeader scaleFactor={scaleFactor} variant="internal" />

        <motion.div
          /*
          animate={{
            x: mouseX ? -mouseX / 450 : 0,
            y: mouseY ? -mouseY / 450 : 0,
            scale: 1.02,
          }}
          transition={{
            duration: 0.2,
            ease: 'linear',
          }}*/
          className="w-full h-full overflow-hidden absolute bottom-0 bg-cover bg-bottom"
          style={{
            backgroundImage: `url('${buildOptimizedAssetPath('images/bg_room', [
              'avif',
              'webp',
            ])}')`,
            boxShadow:
              'inset 0 0 20px 20px rgb(0 0 0 / 50%), inset 0 0 4px 4px rgb(0 0 0 / 90%)',
          }}
        />

        <motion.div
          /*
          animate={{
            x: mouseX ? mouseX / 200 : 0,
            y: mouseY ? mouseY / 200 : 0,
            scale: 1.06,
          }}
          transition={{
            duration: 0.4,
            ease: 'linear',
          }}*/
          className="w-full h-full overflow-hidden absolute bottom-0 bg-cover mix-blend-overlay opacity-40"
          style={{
            backgroundImage: `url('${buildOptimizedAssetPath(
              'images/bg_lights',
              ['avif', 'webp']
            )}')`,
            boxShadow:
              'inset 0 0 20px 20px rgb(0 0 0 / 50%), inset 0 0 4px 4px rgb(0 0 0 / 90%)',
          }}
        />

        <div
          className="w-full h-[120px] 4xl:h-[140px] overflow-hidden absolute bottom-0 bg-cover bg-bottom"
          style={{
            backgroundImage: `url('${buildOptimizedAssetPath('images/table', [
              'avif',
              'jpg',
            ])}')`,
          }}
        />
        <div className="bg-none flex flex-row w-full flex-grow gap-4 py-4 xl:py-5 2xl:py-10 relative overflow-clip">
          <div className="w-5/6 lg:w-4/5 xl:w-2/3 2xl:w-2/3 mx-auto h-full 2xl:p-8 relative transform-gpu -translate-y-8">
            <ComputerFrame>
              <div className="relative flex flex-col items-center justify-center bg-transparent backdrop-blur-lg w-full h-full rounded overflow-hidden">
                <div className="w-20 xl:w-24 2xl:w-28 3xl:w-32 h-20 xl:h-24 2xl:h-28 3xl:h-32 rounded-full block relative bg-white" />
                <p className="mt-2 xl:mt-3 2xl:mt-4 3xl:mt-6 font-bold text-base 2xl:text-lg 3xl:text-xl text-white">
                  {t('welcome')}
                </p>
                <p className="text-xxs 2xl:text-xs 3xl:text-sm text-white text-center">
                  {t('landing-error')}
                  <br /> <b className="inline">ledsp</b> {t('or')}{' '}
                  <b className="inline">demoId</b>.
                </p>

                <span className="absolute space-x-10 w-full bottom-4 2xl:bottom-6 3xl:bottom-8 flex flex-row justify-center items-center">
                  {(
                    <div className="flex flex-col space-y-1 xl:space-y-2 2xl:space-y-3 3xl:space-y-4 text-white text-center mt-auto mb-1">
                      <p className="text-xs font-semibold">Faculty author:</p>
                      <p className="text-lg">Leonardo Caporarello</p>
                    </div>
                  ) ||
                    ['freeze', 'restart', 'shutdown'].map(action => (
                      <span
                        key={action}
                        className="opacity-90 bg-white/0 backdrop-blur-lg hover:bg-white/20 cursor-pointer flex items-center justify-center space-y-2 p-2 rounded-lg flex-col duration-200 ease-in-out transition-all"
                      >
                        <img
                          src={buildAssetPath(`images/${action}.svg`)}
                          alt={action}
                          className="w-6 h-6 invert _nodrag"
                        />
                        <p className="text-xs font-light text-white text-center">
                          {action}
                        </p>
                      </span>
                    ))}
                </span>
              </div>
            </ComputerFrame>
          </div>

          <div className="h-full drop-shadow-lg w-1/3 mt-auto flex">
            <img
              src={buildOptimizedAssetPath('images/plant1', ['avif', 'png'])}
              alt="Giardicus selvaticus"
              title="Giardicus selvaticus"
              className="4xl:translate-y-6"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MissingGameData;
