import PocketBase from 'pocketbase';

export default class PocketbaseClient {
  client: PocketBase;

  constructor({ url }: IPocketbaseConfiguration) {
    this.client = new PocketBase(url);

    this.client.beforeSend = (
      requestURL: string,
      requestConfiguration: any
    ) => {
      delete requestConfiguration.signal;

      return requestConfiguration;
    };
  }

  private async retry(fn: () => Promise<any>, attempts: number = 5) {
    let success = false;
    let attempt = 0;

    while (!success && attempt < attempts) {
      if (attempt > 0)
        console.log(`Retry: Attempt ${attempt} to process request...`);
      try {
        await fn();
        success = true;
      } catch (error) {
        console.error(error);
        attempt++;
      }
    }

    if (!success) throw console.error('Error: Failed to process request!');
  }

  public async create(
    collection: string,
    _id: string,
    payload: Record<string, any>
  ) {
    await this.retry(
      async () =>
        await this.client.records.create(collection, {
          ...payload,
          _id,
        })
    );
  }

  public async update(collection: string, _id: string, payload: any) {
    const existingRecord = await this.findOne(collection, _id);

    if (!existingRecord) throw new Error('Record does not exist. _id: ' + _id);

    await this.client.records.update(collection, existingRecord.id, {
      ...existingRecord,
      ...payload,
      _id: existingRecord._id,
    });
  }

  public async findOne(collection: string, _id: string) {
    try {
      const result = await this.client.records.getFullList(collection, 1, {
        filter: `_id = "${_id}"`,
      });

      return result[0];
    } catch (error) {
      console.error(error);

      return undefined;
    }
  }

  public async findAll(collection: string, filters?: Record<string, any>) {
    let pocketbaseFilters = filters.startingWith?.length
      ? {
          filter: `${filters.startingWith[0]} ~ "${filters.startingWith[1]}"`,
        }
      : undefined;

    return await this.client.records.getFullList(
      collection,
      undefined,
      pocketbaseFilters
    );
  }

  public async upsert(collection: string, _id: string, payload: any) {
    const existingRecord = await this.findOne(collection, _id);

    if (existingRecord)
      // In case the record already exists, we update it directly instead of
      // going through the custom proxy method, in order to avoid a suerflous
      // `findOne` invocation.
      await this.client.records.update(collection, existingRecord.id, {
        ...existingRecord,
        ...payload,
        _id: existingRecord._id, // To make sure we never overwrite `_id`.
      });
    else await this.create(collection, _id, payload);
  }

  public async delete(collection: string, _id: string) {
    const existingRecord = await this.findOne(collection, _id);

    await this.client.records.delete(collection, existingRecord.id);
  }

  public subscribe(
    collection: string,
    id: string | undefined,
    callback: (event: any) => void
  ) {
    this.client.realtime.subscribe(
      `${collection}${id ? `/${id}` : ''}`,
      ({ record }) => callback(record)
    );
  }

  public unsubscribe(collection: string, id: string | undefined) {
    this.client.realtime.unsubscribe(`${collection}${id ? `/${id}` : ''}`);
  }
}

export interface IPocketbaseConfiguration {
  url: string;
}
