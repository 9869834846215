import { toSum } from '../../libs/say-it';
import { IIdealPlan, IPlan } from '../concepts';


export class MacrophaseEvaluatorAlgo {
  
    private readonly splittedPlan: number[][] ;

    constructor(
        private readonly plan: number[],
        private readonly dist: [number, number, number]
    ) {
        this.splittedPlan = this.splitPlan();
    }

    public macroPhaseCorrectness(phase: number): number {

        const sub_plan = this.splittedPlan[phase - 1];
        const count = sub_plan
            .map(a => a === phase ? 1 : 0)
            .reduce(toSum(), 0);

	    return sub_plan.length > 0 ? count / sub_plan.length : 0;
    }

    private splitPlan(): number[][] {

        const plan: number[] = [...this.plan];  // make a copy of the original plan

        return this.dist.slice(0, -1).concat(Infinity)
            .map(f => Math.max(Math.round(f * this.plan.length), 1))
            .map(n => plan.splice(0, n))
    }
}

export class MacrophaseEvaluator {

    private algo: MacrophaseEvaluatorAlgo ;

    private static readonly phaseToNum = new Map<string, number>([
        [ 'preparation'  , 1 ],
        [ 'communication', 2 ],
        [ 'execution'    , 3 ]
    ]);

    constructor(
        plan: IPlan, iPlan: IIdealPlan, dist: [number, number, number]
    ) {
        const planPhases: number[] = plan
            .toMacrophases(iPlan.toMacrophase)
            .map(x => MacrophaseEvaluator.phaseToNum.get(x));

        this.algo = new MacrophaseEvaluatorAlgo(planPhases, dist);
    }

    public evaluate(phase: string): number {

        return this.algo.macroPhaseCorrectness(
            MacrophaseEvaluator.phaseToNum.get(phase)
        );
    }
}
