import { ReactNode } from 'react';
import buildOptimizedAssetPath from '../../core/buildOptimizedAssetPath';

interface IProps {
  children: ReactNode | JSX.Element | JSX.Element[];
}

const PhoneFrame = ({ children }: IProps) => (
  <div className="font-sans -translate-x-8 shadow-xl select-none device device-iphone-x transform-gpu xl:translate-x-1 2xl:translate-x-4 xl:translate-y-2 2xl:translate-y-4">
    <div className="h-full overflow-hidden device-frame">
      <div className="relative w-full h-full rounded-3xl">
        <div
          className="absolute top-0 left-0 z-10 w-full h-full bg-cover pointer-events-none opacity-40 mix-blend-screen"
          style={{
            backgroundImage: `url('${buildOptimizedAssetPath(
              'images/phone-glass-1',
              ['avif', 'jpg']
            )}'`,
          }}
        />
        <div
          className="absolute top-0 left-0 z-10 w-full h-full overflow-hidden bg-cover opacity-50 pointer-events-none filter mix-blend-screen"
          style={{
            backgroundImage: `url('${buildOptimizedAssetPath(
              'images/phone_glass',
              ['avif', 'webp']
            )}'`,
            borderRadius: '40px',
          }}
        />

        <div className="relative flex flex-col justify-start w-full h-full px-2 pt-10 overflow-y-hidden device-content">
          {children}
        </div>
      </div>
    </div>

    <div className="device-stripe" />
    <div className="device-header" />
    <div className="device-sensors" />
    <div className="device-btns" />
    <div className="device-power" />
  </div>
);

export default PhoneFrame;
