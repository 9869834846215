import qs from 'query-string';
import { QSDecode } from 'ledsp-sdk';

export default function useGameQueryParams() {
  const parsed = qs.parse(location.search);
  const ledsp = parsed?.ledsp?.toString();

  try {
    return {
      gameInfoId: ledsp ? QSDecode(ledsp)?.id : undefined,
      demoId: parsed?.demoId?.toString(),
      isObserver: ledsp && QSDecode(ledsp)?.observer,
      idiom: parsed?.idiom?.toString() || 'en',
    };
  } catch (error) {
    console.error('Ledsp query string is invalid', error);
  }

  // ?!
  return {
    gameInfoId: '--null--',
    demoId: parsed?.demoId?.toString(),
    isObserver: false,
    idiom: parsed?.idiom?.toString() || 'en',
  };
}
